.rounded-custom {
  border-radius: 7px; 
}

.font-weight-700 {
  font-weight: 700;
}

.placeholder-italic::placeholder {
  font-style: italic;
}

.font-weight-600 {
  font-weight: 600;
}

.custom-border {
  border: 1px solid #77A507;
}

.custom-shadow {
  box-shadow: 0px 4px 4px 0px #00000040;
}

.button-container {
  margin-top: 1.5rem; /* Adjust this value to move the button up or down */
}

.font-weight-400 {
  font-weight: 400;
}

.custom-borderMarks {
  border-color: rgba(85, 85, 85, 0.5); /* 80 in hex is 50% opacity */
}

.custom-bg-green {
  background-color: #F0FFF6;
}


.bg-custom-light-green-50 {
  background-color: rgba(220, 252, 231, 0.5);
}

.table-cell {
  padding: 0.75rem 1.5rem; 
  text-align: center;
  font-size: 0.875rem; 
  font-weight: 600; 
  color: #4B5563; 
  border: 1px solid white; 
  background-color: #F0FFF6; 
  border-spacing: 0.9rem;
}


/* Remove spinner arrows from number input */
.no-spinner {
  -moz-appearance: textfield; /* For Firefox */
  -webkit-appearance: none; /* For Chrome, Safari, and newer Edge */
  appearance: none; /* For other browsers */
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none; /* For Chrome, Safari, and newer Edge */
  margin: 0; /* Remove margin */
}

/* Hide Edge's built-in password reveal and clear buttons */
input[type="password"]::-ms-reveal {
  display: none;
}

input[type="password"]::-ms-clear {
  display: none;
}

.table-container {
  overflow-x: auto;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  flex-wrap: wrap; /* To wrap pagination buttons on smaller screens */
}

.pagination-button {
  padding: 0.5rem 0.75rem;
  margin: 0 0.25rem;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
}

.pagination-button:hover {
  background-color: #f0f0f0;
}

.pagination-button.active {
  background-color: #1ba147;
  color: white;
}

.pagination-button.disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

/* Ensure the pagination container is scrollable on mobile */
.overflow-x-auto {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.inline-flex {
  display: inline-flex;
}


.table-year {
  width: 10%;
}

.table-index {
  width: 15%;
}

.table-name {
  width: 25%;
}

.table-tute {
  width: 15%;
}

.table-marks {
  width: 10%;
}

.table-rank {
  width: 10%;
}

.table-action {
  width: 15%;
}

.table-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.table-empty-row {
  height: 44.8px; 
}

.custom-list li {
  text-indent: -1.5em;
  padding-left: 1.5em;
}






